<template>
  <div class="skiers-container">
    <h1 class="title">All Skiers</h1>
    
    <LoadingSpinner v-if="isLoading" />

    <div v-if="!isLoading && skiers.length" class="skiers-list">
      <div v-for="skier in skiers" :key="skier.id" class="skier-card">
        <img :src="skier.avatar || defaultAvatar" alt="Profile Picture" class="skier-avatar" />
        <div class="skier-info">
          <h2 class="skier-username">
            {{ skier.username }}
            <img
              v-if="skier.verified"
              src="@/assets/verified.png"
              alt="Verified"
              class="verified-badge"
              title="Verified Skier"
            />
          </h2>
          <p class="skier-bio">{{ formatBio(skier.bio || '') }}</p>
        </div>
        <!-- Follow/Unfollow Button on the right side -->
        <button
          class="follow-button"
          @click="toggleFollow(skier)"
          @mouseenter="hoveredSkierId = skier.id"
          @mouseleave="hoveredSkierId = null"
          :class="{ 'unfollow': isFollowing(skier.id) }"
        >
          {{ getButtonText(skier.id) }}
        </button>
      </div>
    </div>

    <div v-else-if="!isLoading">
      <p>No skiers found.</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      userID: null, // Store fetched user ID here
      skiers: [],
      defaultAvatar: '/path/to/default/avatar.png', // Set path to default avatar
      isLoading: true, // Add a loading state
      following: [], // Add following list
      hoveredSkierId: null,
    };
  },
  async created() {
    await this.fetchUserID();
    await this.fetchFollowing();
    await this.fetchSkiers();
    this.isLoading = false; // Set loading to false after all data is fetched
  },
  methods: {
    async fetchUserID() {
      const token = localStorage.getItem('access_token');
      if (token) {
        try {
          const response = await axios.get('https://api.6buoys.com/api/profile/', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.userID = response.data.id; // Store userID from API response
        } catch (error) {
          console.error('Error fetching user ID:', error);
        }
      }
    },
    async fetchFollowing() {
      try {
        // Fetch the following relationships
        const response = await axios.get(`https://api.6buoys.com/api/user-following/${this.userID}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        });

        const followings = response.data; // Following relationships

        // Fetch the profile for each following (receiver)
        this.following = await Promise.all(
          followings.map(async (following) => {
            const profileResponse = await axios.get(`https://api.6buoys.com/api/profile/${following.receiver}/`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              },
            });
            return profileResponse.data; // Return the full profile data
          })
        );

        console.log("Full Following Data with Profiles:", this.following); // Log the full profile data
      } catch (error) {
        console.error('Error fetching following:', error);
      }
    },
    async fetchSkiers() {
      try {
        const response = await axios.get('https://api.6buoys.com/api/profiles/', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        });
        this.skiers = response.data;
      } catch (error) {
        console.error('Error fetching skiers:', error);
      }
    },
    isFollowing(profileID) {
      // Check if the profile is in the following list
      return this.following.some((f) => f.id === profileID);
    },
    formatBio(bio) {
      return bio.length > 140 ? bio.substring(0, 140) + '...' : bio;
    },
    getButtonText(skierId) {
      if (this.isFollowing(skierId)) {
        return this.hoveredSkierId === skierId ? 'Unfollow' : 'Following';
      } else {
        return 'Follow';
      }
    },
    async toggleFollow(profile) {
      try {
        if (this.isFollowing(profile.id)) {
          // Unfollow action
          await axios.delete(`https://api.6buoys.com/api/follow-delete/${this.userID}/${profile.id}/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          });
          // Remove profile from following list
          this.following = this.following.filter((f) => f.id !== profile.id);
        } else {
          await axios.post(`https://api.6buoys.com/api/follow-create/${this.userID}/${profile.id}/`, null, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          });
          // Add profile to following list
          this.following.push(profile);
        }
      } catch (error) {
        console.error('Error toggling follow:', error);
      }
    },
  },
};
</script>

<style scoped>
.skiers-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #192734;
  border-radius: 16px;
  color: #d9d9d9;
}

.title {
  text-align: center;
  color: #1da1f2;
  margin-bottom: 20px;
}

.skiers-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.skier-card {
  display: flex;
  position: relative; /* Add position relative */
  background-color: #15202b;
  padding: 15px;
  border-radius: 12px;
  width: 100%;
  max-width: 350px;
  align-items: flex-start; /* Align items to the top */
  gap: 15px;
  overflow: hidden; /* Prevent content from overflowing */
  min-height: 100px; /* Ensure space for two lines of bio */
}

.skier-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%; /* Make the image round */
  object-fit: cover;  /* Ensure the image fills the circle */
  border: 2px solid #1da1f2;
  flex-shrink: 0; /* Prevent the image from shrinking */
}

.skier-info {
  flex-grow: 1;
  min-width: 0; /* Prevent bio from expanding the container */
  padding-right: 70px; /* Add right padding to avoid overlap with the button */
}

.skier-username {
  margin: 0;
  font-size: 1.2em;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 5px;
}

.verified-badge {
  width: 18px;
  height: 18px;
}

.skier-bio {
  margin: 5px 0 0;
  color: #8899a6;
  font-size: 0.9em;
  white-space: normal; /* Allow bio to wrap */
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 40px; /* Limit the height to two lines of text */
  text-align: left; /* Align bio text to the left */
}

/* Add styles for the follow/unfollow button */
.follow-button {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #1da1f2;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
}

.follow-button.unfollow {
  background-color: transparent;
  border: solid white;
}
.follow-button.unfollow:hover {
  background-color: #ff4757;
  border: solid #ff4757;
  border: none;
}


</style>

<template>
  <div class="timeline">
    <LoadingSpinner v-if="loading" />
    <div v-for="post in posts" :key="post.id" class="post">
      <!-- Post Header and Details -->
      <div class="post-header">
        <img :src="post.author?.avatar || 'default-avatar.png'" alt="Author Profile" class="profile-pic" />
        <div class="post-details">
          <p class="author">{{ post.author?.first_name || 'Unknown' }}</p>
          <p class="post-date">{{ formatDate(post.created) }}</p>
        </div>
        <div class="post-info">
          <span v-if="post.rope" class="info-badge">{{ post.rope }} meter</span>
          <span v-if="post.speed" class="info-badge">{{ post.speed }} kmph</span>
          <div class="menu-container">
            <span class="three-dots" @click="toggleDropdown(post.id)">&#x22EE;</span>
            <div class="dropdown-menu" v-if="isDropdownOpen(post.id)">
              <ul>
                <li @click="reportContent(post.id)">Report content</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Post Content -->
      <div class="post-content">
        <img v-if="post.image" :src="post.image" alt="Post image" class="post-image" />
        <div class="video-container"
            @click="togglePlayPause(post.id)"
            @dblclick="toggleFullscreen(post.id)"
            @touchstart="handleTouchStart"
            @touchmove="handleTouchMove(post.id)">
          <video
            v-if="post.video"
            :ref="'video_' + post.id"
            :src="post.video"
            muted
            autoplay
            loop
            playsinline
            class="post-video"
            @play="updatePlayState(post.id, true)"
            @pause="updatePlayState(post.id, false)"
          ></video>
          <div v-if="!isPlaying(post.id)" class="play-button-overlay">
            <i class="fas fa-play"></i>
          </div>
          <!-- Fullscreen Icon -->
          <div class="fullscreen-icon" @click.stop="toggleFullscreen(post.id)">
            <i class="fas fa-expand"></i>
          </div>
        </div>
        <p class="post-text">{{ post.content }}</p>
      </div>
      <!-- Post Actions -->
      <div class="post-actions">
        <div class="tips-section" @click="redirectToLogin">
          <i class="fas fa-comments"></i>
          <span v-if="post.tips_count !== undefined">{{ post.tips_count }} Waterski Tips</span>
        </div>
        <div class="likes-section" @click="redirectToLogin">
          <i :class="post.liked_by_user ? 'fas fa-heart liked' : 'far fa-heart not-liked'"></i>
          <span v-if="post.likes_count !== undefined">{{ post.likes_count }} Likes</span>
        </div>
      </div>
      <!-- Waterski Tips -->
      <div v-if="post.showTips" class="tips-list">
        <div v-for="tip in post.tips" :key="tip.id" class="tip">
          <div class="tip-header">
            <img :src="tip.user?.avatar || 'default-avatar.png'" alt="Commenter Profile" class="tip-profile-pic" />
            <p class="tip-author">{{ tip.user?.first_name || 'Unknown' }} {{ tip.user?.last_name || '' }}</p>
            <p class="tip-date">{{ formatDate(tip.created) }}</p>
          </div>
          <p class="tip-body">{{ tip.body }}</p>
          <div class="tip-actions">
            <div class="tip-rate">
              <i
                class="fas fa-minus-circle"
                :class="{ 'voted-down': tip.userVote === -1 }"
                @click="redirectToLogin"
              ></i>
              <span>{{ tip.ratecount }}</span>
              <i
                class="fas fa-plus-circle"
                :class="{ 'voted-up': tip.userVote === 1 }"
                @click="redirectToLogin"
              ></i>
            </div>
            <p class="tip-replies" @click="redirectToLogin">
              {{ tip.reply_count }} repl<span v-if="tip.reply_count !== 1">ies</span><span v-else>y</span>
            </p>
          </div>

          <!-- Replies Section -->
          <div v-if="tip.showReplies" class="replies-list">
            <div v-for="reply in tip.replies" :key="reply.id" class="reply">
              <div class="reply-header">
                <img :src="reply.user_details?.avatar || 'default-avatar.png'" alt="Reply Profile" class="reply-profile-pic" />
                <p class="reply-author">{{ reply.user_details?.first_name || 'Unknown' }} {{ reply.user_details?.last_name || '' }}</p>
                <p class="reply-date">{{ formatDate(reply.created) }}</p>
              </div>
              <p class="reply-body">{{ reply.body }}</p>
            </div>

            <div v-if="tip.replies.length === 0" class="no-replies">
              <p>No replies yet. Be the first to reply!</p>
            </div>
            <!-- Add a form to create a new reply -->
            <div class="new-reply-form">
              <input type="text" placeholder="Reply to this tip" @focus="redirectToLogin" />
              <button @click="redirectToLogin">Send</button>
            </div>
          </div>
        </div>
        <div v-if="post.tips.length === 0" class="no-tips">
          <p>No waterski tips yet. Be the first to add one!</p>
        </div>
        <!-- Add a form to create a new waterski tip -->
        <div class="new-tip-form">
          <input type="text" placeholder="Create a waterski tip" @focus="redirectToLogin" />
          <button @click="redirectToLogin">Send</button>
        </div>
      </div>
    </div>
  </div>
</template>

  
  <script>
  import axios from 'axios';
  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import { Capacitor } from '@capacitor/core';
  import { registerPlugin } from '@capacitor/core';

  // Register the FullscreenPlugin
  const FullscreenPlugin = registerPlugin('FullscreenPlugin');

  
  export default {
    name: 'PublicHome',
    components: {
      LoadingSpinner
    },
    data() {
      return {
        posts: [],
        newTip: {},
        newReply: {},
        userVotes: {},
        loading: true, // Initial loading state
        videoStates: {},
        dropdownStates: {}, // Keep track of which dropdown is open
        lastTap: 0, // Track the time of the last tap for double-tap detection
        pinchStartDistance: null,
      };
    },
    created() {
      this.fetchPosts();
    },
    methods: {
      async fetchPosts() {
        try {
          const response = await axios.get('https://api.6buoys.com/api/post-list/');
          this.posts = response.data.map(post => ({
            ...post,
            showTips: false,
            tips: post.tips || [],
            like_id: post.like_id || null,
            author: post.author || { avatar: 'default-avatar.png', first_name: 'Unknown' }
          }));
        } catch (error) {
          console.error('Error fetching posts:', error);
        } finally {
        this.loading = false; // Set loading to false after data is fetched
        }
      },
      togglePlayPause(postId) {
        const videoElement = this.$refs[`video_${postId}`] ? this.$refs[`video_${postId}`][0] : null;
        if (videoElement) {
          if (videoElement.paused) {
            videoElement.play();
          } else {
            videoElement.pause();
          }
        }
      },
      isPlaying(postId) {
        return this.videoStates[postId] || false;
      },
      updatePlayState(postId, isPlaying) {
        this.videoStates[postId] = isPlaying;
      },
      toggleFullscreen(postId) {
        const videoElement = this.$refs[`video_${postId}`] ? this.$refs[`video_${postId}`][0] : null;
        
        if (!videoElement) return;

        // Detect if we're running on a native platform (iOS or Android)
        if (Capacitor.isNativePlatform()) {
          console.log("Native platform detected, calling fullscreen plugin.");

          // Call the custom iOS fullscreen plugin
          FullscreenPlugin.enterFullScreen({
            videoUrl: videoElement.src // Pass the video URL to the native plugin
          }).then(() => {
            console.log("Fullscreen plugin called successfully.");
          }).catch((err) => {
            console.error("Error entering fullscreen on iOS:", err);
          });

        } else {
          console.log("Web platform detected, using standard fullscreen.");
          // Fallback to standard web fullscreen for browsers
          if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
          } else if (videoElement.mozRequestFullScreen) {
            videoElement.mozRequestFullScreen(); // Firefox
          } else if (videoElement.webkitRequestFullscreen) {
            videoElement.webkitRequestFullscreen(); // Chrome, Safari, and Opera
          } else if (videoElement.msRequestFullscreen) {
            videoElement.msRequestFullscreen(); // IE/Edge
          }
        }
      },
      handleTouchStart(event) {
        if (event.touches.length === 2) {
          this.pinchStartDistance = this.getDistanceBetweenTouches(event.touches);
        } else {
          this.pinchStartDistance = null;
        }
      },
      handleTouchMove(postId, event) {
        if (this.pinchStartDistance && event.touches.length === 2) {
          const currentDistance = this.getDistanceBetweenTouches(event.touches);
          if (currentDistance > this.pinchStartDistance + 10) {
            this.toggleFullscreen(postId);
            this.pinchStartDistance = null; // Reset the pinch distance
          }
        }
      },
      getDistanceBetweenTouches(touches) {
        const dx = touches[0].clientX - touches[1].clientX;
        const dy = touches[0].clientY - touches[1].clientY;
        return Math.sqrt(dx * dx + dy * dy);
      },
      async fetchComments(postId) {
        try {
          const response = await axios.get(`https://api.6buoys.com/api/post-comments/${postId}/`);
          const post = this.posts.find(p => p.id === postId);
          post.tips = response.data;
        } catch (error) {
          console.error('Error fetching comments:', error);
        }
      },
      toggleReplies() {
        this.redirectToLogin(); // Redirect to login instead of toggling replies
      },
      formatDate(dateString) {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
      },
      redirectToLogin() {
        this.$router.push({ name: 'Login' });
      },
      toggleDropdown(postId) {
      // Directly modify the reactive object
      this.dropdownStates[postId] = !this.dropdownStates[postId];
      },
      isDropdownOpen(postId) {
        return this.dropdownStates[postId] || false;
      },
      reportContent(postId) {
        // Handle reporting logic here
        alert(`Please sign in to report this post: ${postId}`);
        this.dropdownStates[postId] = false;
      },
    },
  };
  </script>
  
  <style scoped>

  body {
  margin: 0;
  background-color: #15202b;
  color: #d9d9d9;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

  
  .timeline {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #15202b;
  }

  @media screen and (max-width: 600px) {
  .timeline {
    padding: 20px 10px;
  }
}
  
  .post {
    border: 1px solid #38444d;
    border-radius: 16px;
    margin-bottom: 20px;
    background-color: #192734;
    overflow: hidden;
    transition: background-color 0.3s ease;
  }
  
  .post:hover {
    background-color: #22303c;
  }
  
  .post-header {
    display: flex;
    align-items: left;
    text-align: left;
    padding: 12px 16px;
  }
  
  .profile-pic {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 12px;
    border: 2px solid #1da1f2;
  }
  
  .post-details {
    flex-grow: 1;
  }
  
  .author {
    font-weight: bold;
    color: #ffffff;
    margin: 0;
    font-size: 1em;
  }
  
  .post-date {
    font-size: 0.85em;
    color: #8899a6;
    margin: 0;
  }
  
  .post-info {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  
  .info-badge {
    display: inline-block;
    color: #ffffff;
    padding: 4px 10px;
    border: #38444d 1px solid;
    border-radius: 20px;
    font-size: 0.85em;
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
  }
  
  .post-content {
    padding: 12px 16px;
  }

  @media screen and (max-width: 600px) {
  .post-content {
    padding: 10px;
  }
}
  
  .post-image {
    width: 100%;
    border-radius: 16px;
    max-height: 400px;
    object-fit: cover;
    margin-bottom: 12px;
  }
  
  .post-video {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 12px;
  }
  
  .post-text {
    font-size: 1.1em;
    color: #e1e8ed;
    margin: 0;
  }
  
  .post-actions {
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    color: #8899a6;
    font-size: 1;
  }

  .menu-container {
  position: relative;
  display: inline-block;
}

.three-dots {
  cursor: pointer;
  font-size: 18px;
  color: #8899a6; /* Match the color with the rest of the theme */
}

.dropdown-menu {
  display: block; /* Ensure the dropdown shows up */
  position: absolute;
  right: 0;
  background-color: #192734; /* Match the dark background of the app */
  border: 1px solid #38444d; /* Border style to match the app theme */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Slight shadow for depth */
  z-index: 10;
  width: 160px; /* Increase the width for more space */
  border-radius: 8px; /* Round the corners */
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu ul li {
  padding: 10px 15px; /* Adjust padding for better spacing */
  cursor: pointer;
  color: #e1e8ed; /* Light text color for readability */
}

.dropdown-menu ul li:hover {
  background-color: #22303c; /* Slightly lighter dark color on hover */
}

  
  .tips-section {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #8899a6;
  }
  
  .tips-section:hover {
    color: #1da1f2;
  }
  
  .likes-section {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    color: #e0245e;
  }
  
  .likes-section i {
    transition: color 0.2s;
  }
  
  .likes-section i.liked {
    color: #e0245e;
  }
  
  .likes-section i.not-liked {
    color: #ff5a78;
    border: none;
  }
  
  .likes-section i:hover {
    color: #ff5a78;
    transform: scale(1.2);
  }
  
  .tips-list {
    padding: 10px 16px;
    background-color: #15202b;
    border-top: 1px solid #38444d;
  }
  
  .tip {
    padding: 12px;
    border-bottom: 1px solid #38444d;
    background-color: #192734;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .tip-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .tip-profile-pic {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .tip-author {
    font-weight: bold;
    color: #ffffff;
    margin: 0;
  }
  
  .tip-date {
    font-size: 0.8em;
    color: #8899a6;
    margin-left: auto;
  }
  
  .tip-body {
    color: #e1e8ed;
  }
  
  .tip-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  .tip-rate {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #8899a6;
  }
  
  .tip-rate i {
    cursor: pointer;
    transition: color 0.2s;
  }
  
  .tip-rate .voted-up {
    color: #1da1f2;
  }
  
  .tip-rate .voted-down {
    color: #e0245e;
  }
  
  .tip-replies {
    font-size: 0.9em;
    color: #8899a6;
    cursor: pointer;
    transition: color 0.2s;
  }
  
  .tip-replies:hover {
    color: #1da1f2;
  }
  
  .new-tip-form {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .new-tip-form input {
    flex-grow: 1;
    padding: 8px 12px;
    border-radius: 20px;
    border: 1px solid #38444d;
    background-color: #192734;
    color: #e1e8ed;
    margin-right: 10px;
  }
  
  .new-tip-form input::placeholder {
    color: #8899a6;
  }
  
  .new-tip-form button {
    padding: 8px 16px;
    background-color: #1da1f2;
    color: #ffffff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .new-tip-form button:hover {
    background-color: #0c8ddb;
  }
  
  .replies-list {
    margin-top: 10px;
    padding-left: 20px;
    border-left: 2px solid #38444d;
  }
  
  .reply {
    padding: 8px;
    border-bottom: 1px solid #38444d;
    background-color: #15202b;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  
  .reply-header {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }
  
  .reply-profile-pic {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .reply-author {
    font-weight: bold;
    color: #ffffff;
    margin: 0;
  }
  
  .reply-date {
    font-size: 0.8em;
    color: #8899a6;
    margin-left: auto;
  }
  
  .new-reply-form {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
  
  .new-reply-form input {
    flex-grow: 1;
    padding: 6px 10px;
    border-radius: 15px;
    border: 1px solid #38444d;
    background-color: #192734;
    color: #e1e8ed;
    margin-right: 8px;
  }
  
  .new-reply-form button {
    padding: 6px 12px;
    background-color: #1da1f2;
    color: #ffffff;
    border: none;
    border-radius: 15px;
    cursor: pointer;
  }
  
  .new-reply-form button:hover {
    background-color: #0c8ddb;
  }
  
  .voted-up {
    color: #1da1f2;
  }
  
  .voted-down {
    color: #e0245e;
  }

.video-container {
  position: relative;
  width: 100%;
}

.fullscreen-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  z-index: 10;
}

.fullscreen-icon:hover {
  color: #1da1f2;
}

.post-video {
  width: 100%;
  border-radius: 16px;
  margin-bottom: 12px;
}

.play-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: rgba(255, 255, 255, 0.8);
  pointer-events: none;
}

.play-button-overlay i {
  pointer-events: none;
}
  </style>
  
<template>
  <div class="login-container">
    <img src="@/assets/logo.png" alt="Logo" class="post-logo">
    <h2>Login</h2>
    <div class="form-group create-account-link">
      <router-link to="/create-account">or create a new account</router-link>
    </div>
    <form @submit.prevent="loginUser" class="login-form">
      <div class="form-group">
        <label for="username">Username</label>
        <input
          v-model="username"
          type="text"
          id="username"
          class="input-field"
          required
        />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input
          v-model="password"
          type="password"
          id="password"
          class="input-field"
          required
        />
      </div>
      <button type="submit" class="submit-button">Login</button>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </form>
    <div class="form-group forgot-password-link">
      <a href="https://api.6buoys.com/api/password-reset/">Forgot Password?</a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import eventBus from '@/utils/eventBus';

export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: ''
    };
  },
  methods: {
    async loginUser() {
      try {
        const response = await axios.post('https://api.6buoys.com/api/token/', {
          username: this.username,
          password: this.password
        });
        const { access, refresh } = response.data;

        // Store tokens in local storage
        localStorage.setItem('access_token', access);
        localStorage.setItem('refresh_token', refresh);

        // Emit an event to notify the navbar about the login
        eventBus.emit('userLoggedIn');

        // Redirect to the home timeline or dashboard
        this.$router.push('/timeline');
      } catch (error) {
        this.errorMessage = 'Invalid username or password';
      }
    }
  }
};
</script>

<style scoped>
/* Same styles as before */
.login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background-color: #192734;
  border-radius: 16px;
  color: #d9d9d9;
}

h2 {
  text-align: center;
  color: #1da1f2;
  margin-bottom: 20px;
  margin-top: 15px;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

label {
  color: #8899a6;
  margin-bottom: 5px;
}

.input-field {
  padding: 12px;
  background-color: #15202b;
  border: 1px solid #38444d;
  border-radius: 10px;
  color: #d9d9d9;
  font-size: 16px; 
}

.input-field::placeholder {
  color: #8899a6;
}

.submit-button {
  padding: 12px 20px;
  background-color: #1da1f2;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0c8ddb;
}

.create-account-link {
  text-align: center;
  font-size: 12px;
  margin-top: -20px; 
  margin-bottom: 20px;
}

.create-account-link a {
  color: #1da1f2;
  text-decoration: underline;
}

.create-account-link a:hover {
  text-decoration: underline;
  color: #0c8ddb;
}

.forgot-password-link {
  text-align: center;
  font-size: 12px;
  margin-top: 20px;
}

.forgot-password-link a {
  color: #1da1f2;
  text-decoration: underline;
}

.forgot-password-link a:hover {
  text-decoration: underline;
  color: #0c8ddb;
}

.error-message {
  color: #e0245e;
  text-align: center;
  margin-top: 10px;
}

.post-logo {
  display: block;
  margin: 0 auto 5px;
  width: 80px;
}
</style>

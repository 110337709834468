<template>
  <div class="timeline">
    <LoadingSpinner v-if="loadingtimeline" />
    <div v-for="post in posts" :key="post.id" class="post">
      <!-- Post Header and Details -->
      <div class="post-header">
        <img :src="post.author?.avatar || 'default-avatar.png'" alt="Author Profile" class="profile-pic" />
        <div class="post-details">
          <p class="author">{{ post.author?.first_name || 'Unknown' }}</p>
          <p class="post-date">{{ formatDate(post.created) }}</p>
        </div>
        <div class="post-info">
          <span v-if="post.rope" class="info-badge">{{ post.rope }} meter</span>
          <span v-if="post.speed" class="info-badge">{{ post.speed }} kmph</span>
          <div class="menu-container">
            <span class="three-dots" @click="toggleDropdown(post.id)">&#x22EE;</span>
            <div class="dropdown-menu" v-if="isDropdownOpen(post.id)">
              <ul>
                <li @click="reportContent(post.id)">Report content</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Post Content -->
      <div class="post-content">
          <img v-if="post.image" :src="post.image" alt="Post image" class="post-image" />
          <div class="video-container"
              @click="togglePlayPause(post.id)"
              @dblclick="toggleFullscreen(post.id)"
              @touchstart="handleTouchStart"
              @touchmove="handleTouchMove(post.id)">
            <video
              v-if="post.video"
              :ref="'video_' + post.id"
              :src="post.video"
              muted
              autoplay
              loop
              playsinline
              class="post-video"
              @play="updatePlayState(post.id, true)"
              @pause="updatePlayState(post.id, false)"
            ></video>
            <div v-if="!isPlaying(post.id)" class="play-button-overlay">
              <i class="fas fa-play"></i>
            </div>
          </div>
          <p class="post-text">{{ post.content }}</p>
        </div>
      <div id="loading" style="display: none;">Loading...</div>
      <!-- Post Actions -->
      <div class="post-actions">
        <div class="tips-section" @click="toggleTips(post.id)">
          <i class="fas fa-comments"></i>
          <span v-if="post.tips_count !== undefined">{{ post.tips_count }} Waterski Tips</span>
        </div>
        <div class="likes-section" @click="handleLike(post)">
          <i :class="post.liked_by_user ? 'fas fa-heart liked' : 'far fa-heart not-liked'"></i>
          <span v-if="post.likes_count !== undefined">{{ post.likes_count }} Likes</span>
        </div>
      </div>
      <!-- Waterski Tips -->
      <div v-if="post.showTips" class="tips-list">
        <div v-for="tip in post.tips" :key="tip.id" class="tip">
          <div class="tip-header">
            <img :src="tip.user?.avatar || 'default-avatar.png'" alt="Commenter Profile" class="tip-profile-pic" />
            <p class="tip-author">{{ tip.user?.first_name || 'Unknown' }} {{ tip.user?.last_name || '' }}</p>
            <p class="tip-date">{{ formatDate(tip.created) }}</p>
            <!-- Show delete icon only if the comment belongs to the signed-in user -->
            <i 
              v-if="tip.user?.id === userID" 
              class="fas fa-trash delete-icon" 
              @click="confirmDelete(tip.id)"
              title="Delete Comment"
            ></i>
          </div>
          <p class="tip-body">{{ tip.body }}</p>
          <div class="tip-actions">
            <div class="tip-rate">
              <i
                class="fas fa-minus-circle"
                :class="{ 'voted-down': tip.userVote === -1 }"
                @click="handleVote(tip, -1)"
              ></i>
              <span>{{ tip.ratecount }}</span>
              <i
                class="fas fa-plus-circle"
                :class="{ 'voted-up': tip.userVote === 1 }"
                @click="handleVote(tip, 1)"
              ></i>
            </div>
            <p class="tip-replies" @click="toggleReplies(tip.id)">
              {{ tip.reply_count }} repl<span v-if="tip.reply_count !== 1">ies</span><span v-else>y</span>
            </p>
          </div>

          <!-- Replies Section -->
          <div v-if="tip.showReplies" class="replies-list">
            <div v-for="reply in tip.replies" :key="reply.id" class="reply">
              <div class="reply-header">
                <img :src="reply.user_details?.avatar || 'default-avatar.png'" alt="Reply Profile" class="reply-profile-pic" />
                <p class="reply-author">{{ reply.user_details?.first_name || 'Unknown' }} {{ reply.user_details?.last_name || '' }}</p>
                <p class="reply-date">{{ formatDate(reply.created) }}</p>
                <!-- Trashcan icon for deleting reply -->
                <i 
                  v-if="reply.user_details?.id === userID" 
                  class="fas fa-trash delete-icon" 
                  @click="confirmReplyDelete(reply.id)" 
                  title="Delete Reply"
                ></i>
              </div>
              <p class="reply-body">{{ reply.body }}</p>
            </div>

            <div v-if="tip.replies.length === 0" class="no-replies">
              <p>No replies yet. Be the first to reply!</p>
            </div>
            <!-- Add a form to create a new reply -->
            <div class="new-reply-form">
              <input type="text" v-model="newReply[tip.id]" placeholder="Reply to this tip" />
              <button @click="createReply(tip.id)">Send</button>
            </div>
          </div>
        </div>
        <div v-if="post.tips.length === 0" class="no-tips">
          <p>No waterski tips yet. Be the first to add one!</p>
        </div>
        <!-- Add a form to create a new waterski tip -->
        <div class="new-tip-form">
          <input type="text" v-model="newTip[post.id]" placeholder="Create a waterski tip" />
          <button @click="createTip(post.id)">Send</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: 'HomePage',
  components: {
    LoadingSpinner
  },
  data() {
    return {
      posts: [],
      newTip: {},
      newReply: {},
      userVotes: {},
      dropdownStates: {}, // Keep track of which dropdown is open
      userID: null,
      offset: 0, // Added offset to track the position for pagination
      limit: 5, // Limit of posts to fetch at a time
      loading: false, // Track loading state
      loadingtimeline: true, // Track loading state for timeline
      videoStates: {},
      lastTap: 0, // Track the time of the last tap for double-tap detection
      pinchStartDistance: null,
    };
  },
  metaInfo: {
    title: 'Home Page',
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' }
    ]
  },
  created() {
    this.fetchPosts();
    this.getUserID();
    this.setupScrollListener();
  },
  methods: {
    async fetchPosts() {
      if (this.loading || this.posts.length >= 1000) return; // Stop fetching if loading or limit reached
      this.loading = true;

      try {
        const response = await axios.get('https://api.6buoys.com/api/post-list/', {
          params: {
            limit: this.limit,
            offset: this.offset,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        });

        const newPosts = response.data.map(post => ({
          ...post,
          showTips: false,
          tips: post.tips || [],
          like_id: post.like_id || null,
          author: post.author || { avatar: 'default-avatar.png', first_name: 'Unknown' }
        }));

        this.posts = [...this.posts, ...newPosts]; // Append new posts
        this.offset += this.limit; // Update the offset
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        this.loading = false;
        this.loadingtimeline = false;
      }
    },
    togglePlayPause(postId) {
      const videoElement = this.$refs[`video_${postId}`] ? this.$refs[`video_${postId}`][0] : null;
      if (videoElement) {
        if (videoElement.paused) {
          videoElement.play();
        } else {
          videoElement.pause();
        }
      }
    },
    isPlaying(postId) {
      return this.videoStates[postId] || false;
    },
    updatePlayState(postId, isPlaying) {
      this.videoStates[postId] = isPlaying;
    },
    toggleFullscreen(postId) {
      const videoElement = this.$refs[`video_${postId}`] ? this.$refs[`video_${postId}`][0] : null;
      if (videoElement) {
        if (videoElement.requestFullscreen) {
          videoElement.requestFullscreen();
        } else if (videoElement.mozRequestFullScreen) { // Firefox
          videoElement.mozRequestFullScreen();
        } else if (videoElement.webkitRequestFullscreen) { // Chrome, Safari, and Opera
          videoElement.webkitRequestFullscreen();
        } else if (videoElement.msRequestFullscreen) { // IE/Edge
          videoElement.msRequestFullscreen();
        }
      }
    },
    handleTouchStart(event) {
      if (event.touches.length === 2) {
        this.pinchStartDistance = this.getDistanceBetweenTouches(event.touches);
      } else {
        this.pinchStartDistance = null;
      }
    },
    handleTouchMove(postId, event) {
      if (this.pinchStartDistance && event.touches.length === 2) {
        const currentDistance = this.getDistanceBetweenTouches(event.touches);
        if (currentDistance > this.pinchStartDistance + 10) {
          this.toggleFullscreen(postId);
          this.pinchStartDistance = null; // Reset the pinch distance
        }
      }
    },
    getDistanceBetweenTouches(touches) {
      const dx = touches[0].clientX - touches[1].clientX;
      const dy = touches[0].clientY - touches[1].clientY;
      return Math.sqrt(dx * dx + dy * dy);
    },
    setupScrollListener() {
      window.addEventListener('scroll', () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 && !this.loading) {
          this.fetchPosts(); // Fetch more posts when near bottom
        }
      });
    },
    async fetchComments(postId) {
      const post = this.posts.find(p => p.id === postId);
      if (post) {
        try {
          const response = await axios.get(`https://api.6buoys.com/api/post-comments/${postId}/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          });

          post.tips = await Promise.all(response.data.map(async tip => ({
            ...tip,
            userVote: this.getUserVote(tip.id),
            replies: [],
            reply_count: await this.fetchReplyCount(tip.id),
            showReplies: false,
          })));
          post.showTips = true;
        } catch (error) {
          console.error('Error fetching tips:', error);
        }
      }
    },
    async createTip(postId) {
      if (!this.newTip[postId] || !this.newTip[postId].trim()) return;

      try {
        const token = localStorage.getItem('access_token');
        await axios.post(
          'https://api.6buoys.com/api/comment-create/',
          { post: postId, body: this.newTip[postId] },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.newTip[postId] = '';
        this.fetchComments(postId);
      } catch (error) {
        console.error('Error creating tip:', error);
      }
    },
    async handleLike(post) {
      const liked = post.liked_by_user;
      const token = localStorage.getItem('access_token');

      try {
        if (liked) {
          await axios.delete(`https://api.6buoys.com/api/like-delete/${post.like_id}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          post.likes_count -= 1;
          post.liked_by_user = false;
          post.like_id = null;
        } else {
          const response = await axios.post(
            'https://api.6buoys.com/api/like-create/',
            { post: post.id, user: this.userID, value: 'like' },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          post.likes_count += 1;
          post.liked_by_user = true;
          post.like_id = response.data.id;
        }
      } catch (error) {
        console.error('Error handling like:', error.response ? error.response.data : error.message);
      }
    },
    async handleVote(tip, value) {
      const currentVote = tip.userVote || 0;

      try {
        const token = localStorage.getItem('access_token');
        const rateId = this.userVotes[tip.id]?.rateId;

        if (currentVote !== 0 && currentVote !== value) {
          await axios.delete(`https://api.6buoys.com/api/rate-delete/${rateId}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.updateTipVotes(tip, currentVote, 0);
        }

        if (currentVote !== value) {
          const requestData = { comment: tip.id, rate: value, user: this.userID };
          let response;

          if (rateId && currentVote !== 0) {
            response = await axios.post(
              `https://api.6buoys.com/api/rate-update/${rateId}/`,
              requestData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            response = await axios.post(
              'https://api.6buoys.com/api/rate-create/',
              requestData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }

          this.userVotes[tip.id] = {
            rateId: response.data.id,
            rate: value,
          };
          this.updateTipVotes(tip, 0, value);
        } else if (currentVote === value) {
          await axios.delete(`https://api.6buoys.com/api/rate-delete/${rateId}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.updateTipVotes(tip, currentVote, 0);
        }
      } catch (error) {
        console.error('Error handling vote:', error.response ? error.response.data : error.message);
      }
    },
    updateTipVotes(tip, currentVote, newVote) {
      if (newVote === 0) {
        tip.ratecount -= currentVote;
        tip.userVote = 0;
        delete this.userVotes[tip.id];
      } else {
        tip.ratecount += newVote - currentVote;
        tip.userVote = newVote;
        this.userVotes[tip.id] = { ...this.userVotes[tip.id], rate: newVote };
      }
    },
    getUserVote(tipId) {
      return this.userVotes[tipId]?.rate || 0;
    },
    async getUserID() {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get('https://api.6buoys.com/api/profile/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.userID = response.data.id; // Ensure this line sets the correct user ID
        this.fetchUserVotes();
      } catch (error) {
        console.error('Error fetching user ID:', error);
      }
    },
    async fetchUserVotes() {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get('https://api.6buoys.com/api/user-comment-rates/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.userVotes = response.data.reduce((acc, vote) => {
          acc[vote.comment] = { rate: vote.rate, rateId: vote.id };
          return acc;
        }, {});
      } catch (error) {
        console.error('Error fetching user votes:', error);
      }
    },
    toggleTips(postId) {
      const post = this.posts.find(p => p.id === postId);
      if (post) {
        post.showTips = !post.showTips;
        if (post.showTips && post.tips.length === 0) {
          this.fetchComments(postId);
        }
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    async confirmDelete(commentId) {
      if (confirm('Are you sure you want to delete this comment?')) {
        try {
          const token = localStorage.getItem('access_token');
          await axios.delete(`https://api.6buoys.com/api/comment-delete/${commentId}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.removeCommentFromPost(commentId);
        } catch (error) {
          console.error('Error deleting comment:', error);
        }
      }
    },
    removeCommentFromPost(commentId) {
      this.posts.forEach(post => {
        post.tips = post.tips.filter(tip => tip.id !== commentId);
      });
    },
    async confirmReplyDelete(replyId) {
      if (confirm('Are you sure you want to delete this reply?')) {
        try {
          const token = localStorage.getItem('access_token');
          await axios.delete(`https://api.6buoys.com/api/reply-delete/${replyId}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.removeReplyFromTip(replyId);
        } catch (error) {
          console.error('Error deleting reply:', error);
        }
      }
    },
    removeReplyFromTip(replyId) {
      this.posts.forEach(post => {
        post.tips.forEach(tip => {
          tip.replies = tip.replies.filter(reply => reply.id !== replyId);
        });
      });
    },
    async fetchReplyCount(tipId) {
      try {
        const response = await axios.get(`https://api.6buoys.com/api/comment-reply-count/${tipId}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        });
        return response.data.count;
      } catch (error) {
        console.error('Error fetching reply count:', error);
        return 0;
      }
    },
    async createReply(tipId) {
      if (!this.newReply[tipId] || !this.newReply[tipId].trim()) return;

      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.post(
          'https://api.6buoys.com/api/reply-create/',
          {
            comment: tipId,
            body: this.newReply[tipId],
            user: this.userID, // Include the user ID in the payload
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.id) {
          this.newReply[tipId] = ''; // Clear the input field

          // Update the replies list in the UI
          const post = this.posts.find(post =>
            post.tips.some(tip => tip.id === tipId)
          );
          const tip = post.tips.find(tip => tip.id === tipId);
          tip.replies.push(response.data); // Add new reply to the list
          tip.reply_count += 1; // Increment reply count
        } else {
          console.error('Reply creation failed', response.data);
        }
      } catch (error) {
        console.error('Error creating reply:', error);
      }
    },
    async toggleReplies(tipId) {
      const post = this.posts.find(p => p.tips.some(tip => tip.id === tipId));
      const tip = post.tips.find(tip => tip.id === tipId);
      tip.showReplies = !tip.showReplies;

      if (tip.showReplies && tip.replies.length === 0) {
        try {
          const response = await axios.get(
            `https://api.6buoys.com/api/comment-replies/${tipId}/`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              },
            }
          );
          tip.replies = response.data; // Populate replies
          console.log(tip.replies); // Log replies to inspect the user_details field
        } catch (error) {
          console.error('Error fetching replies:', error);
        }
      }
    },
    toggleDropdown(postId) {
      // Directly modify the reactive object
      this.dropdownStates[postId] = !this.dropdownStates[postId];
    },
    isDropdownOpen(postId) {
      return this.dropdownStates[postId] || false;
    },
    async reportContent(postId) {
      this.dropdownStates[postId] = false;

      try {
        const token = localStorage.getItem('access_token');
        const userId = this.userID; 

        if (!token) {
          alert('You need to be logged in to report content.');
          return;
        }

        if (!userId) {
          console.error('User ID not found!');
          return;
        }

        const response = await axios.post(
          'https://api.6buoys.com/api/report-post/', 
          { 
            post: postId,  
            user: userId   
          },  
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log('API Response:', response.data);

        alert(`Reported content for post ID: ${postId}. Thank you for your feedback!`);
      } catch (error) {
        console.error('Error reporting post:', error.response ? error.response.data : error.message);
        alert('There was an error reporting the post. Please try again later.');
      }
    },
  },
};
</script>

<style scoped>
/* General Styles */
body {
  background-color: #15202b;
  color: #d9d9d9;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}


.timeline {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #15202b;
}

@media screen and (max-width: 600px) {
  .timeline {
    padding: 20px 10px;
  }
}

.post {
  border: 1px solid #38444d;
  border-radius: 16px;
  margin-bottom: 20px;
  background-color: #192734;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.post:hover {
  background-color: #22303c;
}

.post-header {
  display: flex;
  align-items: left;
  text-align: left;
  padding: 12px 16px;
}

.profile-pic {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
  border: 2px solid #1da1f2;
}

.post-details {
  flex-grow: 1;
}

.author {
  font-weight: bold;
  color: #ffffff;
  margin: 0;
  font-size: 1em;
}

.post-date {
  font-size: 0.85em;
  color: #8899a6;
  margin: 0;
}

.post-info {
  display: flex;
  gap: 8px;
  align-items: center;
}

.info-badge {
  display: inline-block;
  color: #ffffff;
  padding: 4px 10px;
  border: #38444d 1px solid;
  border-radius: 20px;
  font-size: 0.85em;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
}

.post-content {
  padding: 12px 16px;
}

@media screen and (max-width: 600px) {
  .post-content {
    padding: 10px;
  }
}

.post-image {
  width: 100%;
  border-radius: 16px;
  max-height: 400px;
  object-fit: cover;
  margin-bottom: 12px;
}

.post-video {
  width: 100%;
  border-radius: 16px;
  margin-bottom: 12px;
}

.post-text {
  font-size: 1.1em;
  color: #e1e8ed;
  margin: 0;
}

.post-actions {
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  color: #8899a6;
  font-size: 1em;
}

.menu-container {
  position: relative;
  display: inline-block;
}

.three-dots {
  cursor: pointer;
  font-size: 18px;
  color: #8899a6; /* Match the color with the rest of the theme */
}

.dropdown-menu {
  display: block; /* Ensure the dropdown shows up */
  position: absolute;
  right: 0;
  background-color: #192734; /* Match the dark background of the app */
  border: 1px solid #38444d; /* Border style to match the app theme */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Slight shadow for depth */
  z-index: 10;
  width: 160px; /* Increase the width for more space */
  border-radius: 8px; /* Round the corners */
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu ul li {
  padding: 10px 15px; /* Adjust padding for better spacing */
  cursor: pointer;
  color: #e1e8ed; /* Light text color for readability */
}

.dropdown-menu ul li:hover {
  background-color: #22303c; /* Slightly lighter dark color on hover */
}


.tips-section {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #8899a6;
}

.tips-section:hover {
  color: #1da1f2;
}

.likes-section {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: #e0245e;
}

.likes-section i {
  transition: color 0.2s;
}

.likes-section i.liked {
  color: #e0245e;
}

.likes-section i.not-liked {
  color: #ff5a78;
  border: none;
}

.likes-section i:hover {
  color: #ff5a78;
  transform: scale(1.2);
}

.tips-list {
  padding: 10px 16px;
  background-color: #15202b;
  border-top: 1px solid #38444d;
}

.tip {
  padding: 12px;
  border-bottom: 1px solid #38444d;
  background-color: #192734;
  border-radius: 8px;
  margin-bottom: 10px;
}

.tip-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.tip-profile-pic {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.tip-author {
  font-weight: bold;
  color: #ffffff;
  margin: 0;
}

.tip-date {
  font-size: 0.8em;
  color: #8899a6;
  margin-left: auto;
}

.delete-icon {
  color: #e0245e;
  cursor: pointer;
  margin-left: 10px;
  transition: color 0.2s;
}

.delete-icon:hover {
  color: #ff5a78;
}

.tip-body {
  color: #e1e8ed;
}

.tip-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.tip-rate {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #8899a6;
}

.tip-rate i {
  cursor: pointer;
  transition: color 0.2s;
}

.tip-rate .voted-up {
  color: #1da1f2;
}

.tip-rate .voted-down {
  color: #e0245e;
}

.tip-replies {
  font-size: 0.9em;
  color: #8899a6;
  cursor: pointer;
  transition: color 0.2s;
}

.tip-replies:hover {
  color: #1da1f2;
}

.new-tip-form {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.new-tip-form input {
  flex-grow: 1;
  padding: 8px 12px;
  border-radius: 20px;
  border: 1px solid #38444d;
  background-color: #192734;
  color: #e1e8ed;
  margin-right: 10px;
}

.new-tip-form input::placeholder {
  color: #8899a6;
}

.new-tip-form button {
  padding: 8px 16px;
  background-color: #1da1f2;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.new-tip-form button:hover {
  background-color: #0c8ddb;
}

.replies-list {
  margin-top: 10px;
  padding-left: 20px;
  border-left: 2px solid #38444d;
}

.reply {
  padding: 8px;
  border-bottom: 1px solid #38444d;
  background-color: #15202b;
  border-radius: 8px;
  margin-bottom: 8px;
}

.reply-header {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.reply-profile-pic {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
}

.reply-author {
  font-weight: bold;
  color: #ffffff;
  margin: 0;
}

.reply-date {
  font-size: 0.8em;
  color: #8899a6;
  margin-left: auto;
}

.new-reply-form {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.new-reply-form input {
  flex-grow: 1;
  padding: 6px 10px;
  border-radius: 15px;
  border: 1px solid #38444d;
  background-color: #192734;
  color: #e1e8ed;
  margin-right: 8px;
}

.new-reply-form button {
  padding: 6px 12px;
  background-color: #1da1f2;
  color: #ffffff;
  border: none;
  border-radius: 15px;
  cursor: pointer;
}

.new-reply-form button:hover {
  background-color: #0c8ddb;
}

.voted-up {
  color: #1da1f2;
}

.voted-down {
  color: #e0245e;
}
.video-container {
  position: relative;
  width: 100%;
}

.post-video {
  width: 100%;
  border-radius: 16px;
  margin-bottom: 12px;
}

.play-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: rgba(255, 255, 255, 0.8);
  pointer-events: none;
}

.play-button-overlay i {
  pointer-events: none;
}
</style>
